<template>
  <div class="orange-page">
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: 'Orange',
  mounted() {},
};
</script>
<style scoped lang="scss">
  .orange-page {
    width: 100%;
    max-width: 750px;
    margin: auto;
    height: 100%;
    position: relative;
    background: #fff;
  };
</style>
